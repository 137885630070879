function Service() {
  this.getHeaders = (): Headers => {
    const headers: Headers = new Headers();
    headers.set('content-type', 'application/json');
    headers.set('accept', 'application/json, */*;q=0.9');
    return headers;
  };

  this.get = async (
    endpoint: string,
    params?: Record<string, any>,
    signal?: AbortSignal
  ): Promise<Response> => {
    const url = new URL(endpoint);
    const validParameters = params ? JSON.parse(JSON.stringify(params)) : {};
    url.search = new URLSearchParams(validParameters).toString();

    return fetch(url.href, {
      signal,
      method: 'GET',
      headers: this.getHeaders()
    }).then(response => {
      if (!response.ok) {
        console.log(response);
      }
      return response.json();
    });
  };

  this.post = async (
    endpoint: string,
    params?: Record<string, any>,
    signal?: AbortSignal
  ): Promise<Response> => {
    const url = new URL(endpoint);
    return fetch(url.href, {
      signal,
      method: 'POST',
      headers: this.getHeaders(),
      body: JSON.stringify(params)
    }).then(response => {
      if (!response.ok) {
        console.log(response);
      }
      return response.json();
    });
  };
}

export default new Service();
