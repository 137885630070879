import { mountPriceTitle } from 'utils/price';
import { sendAnalyticsEvent } from 'utils/datalayer';
import { Product as ProductCore } from '@core/domain/models/product';
import SearchService from '../@marketplace/service';

export const productsArray = (products : Product[] | ProductCore[], discount = false) => {
  const productIdsArray = products.map(product => {
    const { price, offer, coupons, productId } = product || {};

    //  send only products with price not defined
    if (!price) {
      if (coupons && coupons.length >= 1) {
        if (offer) {
          return {
            productId: `${productId || ''}`,
            offerKey: `${offer || ''}`,
            couponOfferCode: `${!discount ? '' : coupons[0]?.code}`
          };
        }
        return {
          productId: `${productId || ''}`,
          couponOfferCode: `${!discount ? '' : coupons[0]?.code}`
        };
      }

      if (offer) {
        return {
          productId,
          offerKey: `${offer || ''}`
        };
      }

      return {
        productId
      };
    }

    return undefined;
  });

  return productIdsArray.filter(product => product?.productId);
};

export const getPriceTotal = async (products, productIdsArray) => {
  const productsPrice = await SearchService.getCheckoutPrice(
    productIdsArray
  );

  products.map(product => {
    const productReturned = productsPrice.filter(
      productPrice => product.productId === productPrice.productId
    );

    if (productReturned && productReturned.length >= 1) {
      product.price = mountPriceTitle(productReturned[0]);
    } else {
      sendAnalyticsEvent({
        event: 'noninteraction',
        event_category: 'Product:NoPrice:Single',
        event_label: `productId-${product.productId}`
      });
    }
  });

  return productsPrice;
};
