import React from 'react';
import classNames from 'classnames';
import SeasonSmallBanner from '../../components/Banner/SeasonSmallBanner';

type CampaignBannerProps = {
  bannerCampaign: { [key: string]: string };
  isMobile: boolean;
  source?: string;
};

function CampaignBanner({
  bannerCampaign,
  isMobile,
  source
}: CampaignBannerProps) {
  const ctaMobile =
    bannerCampaign?.ctaUrl_MOBILE || bannerCampaign?.BANNER_RESULTS_PAGE_LINK;
  const linkBanner = isMobile
    ? ctaMobile
    : bannerCampaign?.ctaUrl || bannerCampaign?.BANNER_RESULTS_PAGE_LINK;

  if (bannerCampaign && Object.keys(bannerCampaign).length > 0) {
    return (
      <div className={classNames({ 'page-container__content': !isMobile })}>
        <SeasonSmallBanner
          link={linkBanner}
          button={
            isMobile
              ? bannerCampaign?.BANNER_HOME_BUTTON_PT_MOBILE ||
                bannerCampaign?.BANNER_HOME_BUTTON_PT
              : bannerCampaign?.BANNER_HOME_BUTTON_PT
          }
          title={
            isMobile
              ? bannerCampaign?.BANNER_RESULTS_PAGE_TITLE_PT_MOBILE ||
                bannerCampaign?.BANNER_RESULTS_PAGE_TITLE_PT
              : bannerCampaign?.BANNER_RESULTS_PAGE_TITLE_PT
          }
          subtitle={
            isMobile
              ? bannerCampaign?.BANNER_RESULTS_PAGE_SUBTITLE_PT_MOBILE ||
                bannerCampaign?.BANNER_RESULTS_PAGE_SUBTITLE_PT
              : bannerCampaign?.BANNER_RESULTS_PAGE_SUBTITLE_PT
          }
          color={
            isMobile
              ? bannerCampaign?.BANNER_RESULTS_PAGE_COLOR_MOBILE ||
                bannerCampaign?.BANNER_RESULTS_PAGE_COLOR
              : bannerCampaign?.BANNER_RESULTS_PAGE_COLOR
          }
          banner={
            isMobile
              ? bannerCampaign?.BANNER_RESULTS_PAGE_IMAGE_MOBILE ||
                bannerCampaign?.BANNER_RESULTS_PAGE_IMAGE
              : bannerCampaign?.BANNER_RESULTS_PAGE_IMAGE
          }
          logo={
            isMobile
              ? bannerCampaign?.BANNER_RESULTS_PAGE_LOGO_MOBILE ||
                bannerCampaign?.BANNER_RESULTS_PAGE_LOGO
              : bannerCampaign?.BANNER_RESULTS_PAGE_LOGO
          }
          main={
            isMobile
              ? bannerCampaign?.BANNER_RESULTS_PAGE_MAIN_MOBILE ||
                bannerCampaign?.BANNER_RESULTS_PAGE_MAIN
              : bannerCampaign?.BANNER_RESULTS_PAGE_MAIN
          }
          campaignObj={bannerCampaign}
          priority
          isSetup={!!bannerCampaign?.SETUP}
          source={source}
        />
      </div>
    );
  }
  return null;
}

export default React.memo(CampaignBanner);
