export const featureSwitchKey = {
  CAS_IS_ENABLED: 'CAS_IS_ENABLED',
  ENABLE_CLARITY: 'ENABLE_CLARITY',
  INTERNAL_CHECKOUT_IS_ENABLED: 'INTERNAL_CHECKOUT_IS_ENABLED',
  SHOW_NEW_CHECKOUT: 'api-display-back-enable-payment-display-gateway'
};

export const defaultFeatureGatewayPrices = {
  key: featureSwitchKey.SHOW_NEW_CHECKOUT,
  lastResponseUpdate: '',
  source: 'EXTERNAL_SERVICE',
  value: false
};
