import { Component } from 'react';

import { MarketplaceContext } from '../../components/core/Provider/MarketplaceProvider/index';
import { FILTER } from '../../constants';
import { generateURLQueryString, addParamsOriginToUrl } from '../../utils/url';

const withApplyFilter = BaseComponent => {
  return class EnhancedComponent extends Component {
    static contextType = MarketplaceContext;

    applyFilter = async (
      searchText,
      data,
      useQualityScoreQueries,
      router,
      hideCategories = false
    ) => {
      const params = Object.keys(data);
      const currentRouterPath = router?.route;
      const querystring = [];
      let filterApplied = false;
      let paramData;

      params.forEach(param => {
        if (hideCategories && param === 'categories') {
          return;
        }

        if (param === 'sort') {
          querystring.push(`${param}=${data[param]}`);
          return;
        }

        if (data[param].length > 0) {
          if (!filterApplied) {
            filterApplied = true;
          }

          if (Array.isArray(data[param]) && data[param].length > 1) {
            paramData = data[param]?.sort().join(FILTER.SEPARATOR);
          } else {
            paramData = data[param];
          }
          if (paramData) {
            querystring.push(`${param}=${paramData}`);
          }
        }
      });

      const currentPathname = window.location.pathname;

      if (searchText) {
        querystring.push(`q=${searchText}`);
      }

      const { marketplace: { resultsRelatedPage = {} } } = this.context;

      let dataFiltered;

      if (resultsRelatedPage && resultsRelatedPage.totalProducts > 0) {
        dataFiltered = await this.context.actions.applyFilterRelatedResultsPage(
          data,
          useQualityScoreQueries
        );
      } else {
        dataFiltered = await this.context.actions.applyFilterResultsPage(
          data,
          useQualityScoreQueries
        );
      }

      const suffix = `${querystring.length > 0 ? '?' : ''}${querystring.join(
        '&'
      )}`;

      // removendo querystring que não devem ser enviadas a diante
      const { query: { sck, src, initialSelectedCategory, season, q, sort, categories, coupons, groupKeys, ratings, languages, formats, optionsPayment, topifications, topics, ...rest } = {} } = this.props;
      let paramsUrl = {
        ...rest
      };

      paramsUrl = addParamsOriginToUrl(paramsUrl, sck, src);

      const url = `${currentPathname}${suffix}`;
      const routerUrl = `${currentRouterPath}${suffix}`;
      const finalUrl = generateURLQueryString(url, paramsUrl);

      if (currentRouterPath && !season) {
        router.push(finalUrl, undefined, { shallow: true });
      }

      return dataFiltered;
    };

    render() {
      return <BaseComponent applyFilter={this.applyFilter} {...this.props} />;
    }
  };
};

export default withApplyFilter;
