import classnames from 'classnames';
import Image from 'next/image';
import { loaderImage } from 'utils/image';
import * as DatahubService from 'utils/datahub';
import { EVENT_ACTIONS } from 'utils/datahub.constants';
import Cookie from 'js-cookie';
import { fireProduct } from '../../../constants';

type BannerProps = {
  title: string | undefined;
  banner: string;
  button: string;
  campaignObj: { [key: string]: string };
  color: string;
  isSetup: boolean;
  link: string;
  logo: string;
  main: string | undefined;
  priority: boolean;
  source: string;
  subtitle: string;
};

function SeasonSmallBanner(props: BannerProps) {
  const renderButton = () => {
    return (
      <div className="season-small-banner__cta">
        <span
          className="season-small-banner__cta__button"
          style={{
            backgroundColor: props?.color || 'white',
            color: 'white'
          }}
        >
          {props?.button}
        </span>
      </div>
    );
  };

  const clickBanner = () => {
    if (props?.link.includes(fireProduct.LINK)) {
      DatahubService.sendEvent({
        action: EVENT_ACTIONS.CLICK,
        sessionId: Cookie.get('sid'),
        productId: fireProduct?.PRODUCT_ID,
        origin: fireProduct.TAG,
        source: props?.source
      });
    }
  };

  if (!props?.banner && !props?.link) {
    return null;
  }

  return (
    <div
      className={classnames(
        'season-small-banner',
        `${props?.campaignObj.PREFIX ? props?.campaignObj.PREFIX : ''}`,
        { 'setup-campaign': props?.isSetup }
      )}
    >
      <Image
        src={props?.banner}
        loader={loaderImage}
        layout="fill"
        objectFit="cover"
        priority={props?.priority || true}
        className="season-small-banner__background-image"
        alt={props?.title || ''}
      />
      <a
        href={props?.link}
        onClick={() => clickBanner()}
        className="season-small-banner__link"
      >
        <div className="season-small-banner__container zcdc">
          <div
            className={classnames('season-small-banner__content', {
              'season-small-banner__content-setup': !props?.isSetup
            })}
          >
            <div
              className={classnames('season-small-banner__content__logo', {
                'season-small-banner__content__logo-setup': !props?.isSetup
              })}
            >
              <Image
                src={props?.logo}
                loader={loaderImage}
                layout="fill"
                objectFit="contain"
                priority={false}
                alt=""
              />
            </div>
            <div>
              {props?.subtitle && (
                <div
                  className="season-small-banner__content__text"
                  style={{
                    color: '#fff'
                  }}
                >
                  {props?.title && (
                    <span className="season-small-banner__content__text-main">
                      {props?.title}
                    </span>
                  )}
                  {props?.subtitle}
                </div>
              )}
              {props?.button && renderButton()}
            </div>
          </div>
          <div className="season-small-banner__percentage">
            {props?.main && (
              <Image
                src={props?.main}
                loader={loaderImage}
                layout="fill"
                objectFit="contain"
                priority={false}
                alt=""
              />
            )}
          </div>
          <div className="season-small-banner__button-courses">
            {props?.button && renderButton()}
          </div>
        </div>
      </a>
    </div>
  );
}

export default SeasonSmallBanner;
