import React from 'react';
import Image, { ImageProps } from 'next/image';
import classnames from 'classnames';
import { loaderImage } from '../../../utils/image';

type Props = ImageProps;

function IconsImage(props: Props) {
  return (
    <Image
      src={props?.src}
      width={props.width || 62}
      height={props.height || 62}
      loader={loaderImage}
      quality={props.quality || 50}
      loading="lazy"
      priority={false}
      alt={props?.alt || 'Image Icon'}
      className={classnames('image-icon', props.className)}
    />
  );
}

export default React.memo(IconsImage);
