export enum PaymentOptions {
  BILLET = 'BILLET',
  BILLET_MOBILE = 'BILLET_MOBILE',
  CREDIT_CARD = 'CREDIT_CARD',
  DIRECT_BANK_TRANSFER_ADYEN_SOFORT = 'DIRECT_BANK_TRANSFER_ADYEN_SOFORT',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
  EBANX = 'EBANX',
  GOOGLE_PAY = 'GOOGLE_PAY',
  HOTMART_ACCOUNT_BALANCE = 'HOTMART_ACCOUNT_BALANCE',
  INTERNATIONAL_PAYPAL = 'INTERNATIONAL_PAYPAL',
  MULTIPLE_CREDIT_CARDS = 'MULTIPLE_CREDIT_CARDS',
  PAYPAL = 'PAYPAL',
  PICPAY = 'PICPAY',
  PIX = 'PIX',
  SAMSUNG_PAY = 'SAMSUNG_PAY',
  VIRTUAL_DEBIT_CARD = 'VIRTUAL_DEBIT_CARD'
}

export const checkoutPaymentOptions = {
  LOCALE: {
    PT: 'pt-BR',
    ES: 'es-ES'
  },
  OPERATION: {
    ADD: 'ADD'
  },
  TYPE: {
    COUPON: 'COUPON',
    FULL_PRICE: 'FULL_PRICE',
    SUBSCRIPTION: 'SUBSCRIPTION',
    VAT: 'TAX_FEDERAL'
  }
};

export type Label = {
  key: string;
  parameters?: (number | undefined | string)[];
};

type AdditionalData = {
  banks?: { [key: string]: string };
  isCouponApplied?: boolean;
  provider?: string;
  subTypes?: Array<string>;
  isSmartInstallments?: boolean;
  fieldType?: any;
  minValuePerCard?: number;
  allowTrial?: boolean;
  dueDay?: number;
  isPaypalOneClick?: boolean;
  methods?: Array<any>;
  parentOffer?: string;
  hideMethod?: boolean;
  disclaimer?: any;
  ebanxIntegrationKey?: string;
};

export type Amount = {
  value: number;
  currency: string;
  label?: Label;
};

export type Installment = {
  number: number;
  amount: Amount;
  fullAmount: Amount;
  interest?: Amount;
  installmentMethod?: string;
};

type SummaryItem = {
  type: 'COUPON' | 'FULL_PRICE' | 'TAX_FEDERAL' | 'TAX_PROVINCIAL';
  name?: string;
  operation: 'ADD' | 'NONE' | 'SUBTRACT' | 'OMIT';
  quantity?: number;
  percent: number;
  value: number;
  currency: string;
  installments?: { [key: string]: number };
  locationIsoCode?: string;
};

export type Summary = {
  items: Array<SummaryItem>;
};

export type PaymentMethod = {
  additionalData?: AdditionalData;
  amount: Amount;
  gateways?: Array<string>;
  installments?: Array<Installment>;
  interestRate?: number;
  offer: string;
  priceDescription?: Label;
  subType?: string;
  summary?: Summary;
  requiredFields?: Array<string>;
  type: string;
};
