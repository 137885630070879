export const deleteUndefined = (obj: object) => {
  const newObj = {};

  if (Array.isArray(obj)) {
    return obj;
  }

  Object.keys(obj).forEach(key => {
    if (obj[key] === Object(obj[key])) newObj[key] = deleteUndefined(obj[key]);
    else if (obj[key] !== undefined) newObj[key] = obj[key];
  });

  return newObj;
};

export function objectOf(keys: string[], object: object) {
  let variation = '';

  keys.forEach(key => {
    if (object[key]) {
      variation = key;
    }
  });

  return variation;
}

export function extractFeatureValueByKey(
  object: [FeatureKeyProps],
  key: string
) {
  if (object && Object.values(object)) {
    return Object.values(object)?.find(
      feature => feature.key === key
    );
  }

  return false;
}
