import config from '../../env.config';

export const loaderImage = ({ src, width }) => {
  return `${src}?w=${width || 300}`;
};

export const sparkleLoaderImage = ({ src, width = 120 }) => {
  return `${src}?dimensions=${width}x${width}`;
};

export const loaderStaticImage = ({ src } : ImageNextOptions) => {
  return `${config.envConfig.APP.URL}/${src}`;
};

export const generateSrcSet = ({ src }) => {
  return `${src} 360w, ${src} 780w, ${src} 920w`;
};
